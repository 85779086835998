

@media (max-width: 768px) {
  #first{
    #hero{
      .hero{
        &--top{
          flex-direction: column;
          h1{
            font-size: 32.8vw;
          }
          &__line{
            margin-top: -15vw;
            img{
              width: 23vw;
            }
         h2{
           font-size: 32.8vw;
         }
          }
        }
        &--center{
          margin: -14vw 0 -5vw 0;
          h1{
            font-size: 32.8vw;
          }
          img{
            width: 15vw;
          }
          &__line{
            margin-top: -14vw;
            h2{
              font-size: 30.8vw;
            }
          }
        }
        h3{
          font-size: 9.8vw;

          line-height: 8vw;
        }
      }
    }
    #fronk{
 .title{
   flex-direction: column-reverse;
   gap: 5vw;
   &--left{
     width: 100%;
     h4{
       font-size: 2.3vw;
     }
     img{
       width: 50vw;
     }
   }
   &--video{
     width: 95vw;
     height: 52vw;
   }
 }
    }
    #avatar{
      .avatar{
        flex-direction: column;
        .ava-img{
          width: 91vw;
          height: 78vw;
        }
        gap: 4vw;
        &--right{
          width: 100%;
          h4{
            font-size: 2.4vw;
          }
          &__bottom{
            &--clothes{
              gap: 2vw;
              img{
                width: 10vw;
                height: 10vw;
              }
            }
            .bottom-img{
              display: none;
            }
          }
        }
      }
    }
    #question{
      margin-bottom: 10vw;
      .question{
        width: 100%;
        flex-direction: column;
        gap: 3vw;

        &--quest{
          width: 94vw !important;
          height: 27vw;
          div{
            h1{
              font-size: 14vw;
            }
            img{
              margin: 0 0 0 -3%;
            }
          }
          h3{
            font-size: 2.4vw;
            width: 85%;
            text-align: center;
          }
        }
        &--quest:nth-child(1){
          div{
           img{
             width: 10vw;
             height: 11vw;
           }
          }

        }
        &--quest:nth-child(2){
          div{
            img{
              width: 11vw;
              height: 11vw;
            }
          }

        }
        &--quest:nth-child(3){
          div{
            img{
              width: 11vw;
              height: 11vw;
            }
          }

        }
        &--quest:nth-child(4){
          div{
            img{
              width: 11vw;
              height: 11vw;
            }
          }

        }
      }
    }
    #accordion{
      display: none;
    }
  }
}