@media (max-width: 1280px) {
  #landSale {
    #hero {
      .container {
        .hero {
          &--journey {
            h3 {
              font-size: 65.199px !important;
            }
          }
        }
      }
    }
    #section2 {
      .section2 {
        &--blocks {
          &__block {
            max-width: 44.3%;
          }
          &__image {
            max-width: 48.3%;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  #landSale {
    #hero {
      .container {
        .hero {
          &--journey {
            h3 {
              font-size: 64.199px !important;
            }
          }
        }
      }
    }
    #section2 {
      .section2 {
        &--blocks {
          &__block {
            max-width: 43.9%;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  #landSale {
    #hero {
      .container {
        .hero {
          &--journey {
            h3 {
              font-size: 55.199px !important;
            }
          }
        }
      }
    }
    #section2 {
      .section2 {
        &--blocks {
          &__block {
            max-width: 43%;
          }

          &__image {
            max-width: 47.3%;
          }
        }
      }
    }
  }
}
@media (max-width: 993px) {
  #landSale {
    #hero {
      .container {
        .hero {
          &--journey {
            h3 {
              font-size: 53.199px !important;
            }
          }
        }
      }
    }
    #section2 {
      .section2 {
        &--blocks {
          &__block {
            // max-width: 42%;
          }
        }
      }
    }
  }
}
@media (max-width: 912px) {
  #landSale {
    #hero {
      .container {
        .hero {
          &--journey {
            h3 {
              font-size: 43.199px !important;
            }
          }
        }
      }
    }
    #section {
      .second {
        height: 83vw !important;
        margin-top: 20% !important;
        &--line {
          width: 85vw !important;
          height: 43vw !important;
        }
        &--page {
          display: flex;
          flex-direction: column !important;
          justify-content: space-between !important;
          &__left {
            position: relative;
            h1 {
              font-size: 23vw !important;
              display: flex;
              left: -40vw !important;
              justify-content: center;
            }
            img {
              width: 63vw !important;
              left: -10vw  !important;
              position: absolute !important;
              top: -13vw !important  ;
            }
          }
          &__title {
            top: 50vw !important;
          position: absolute;
            p {
              font-size: 3vw !important;
              width: 80vw !important;
            }
            &--text {
              display: none;
              h3 {
                display: none;
              }
            }
          }
        }
      }
    }
    #section2{
      .section2{
        &--blocks {
          align-items: center;          
          .section2--blocks__block {
            width: 44vw !important; 
            height: 16vw !important; 
          }
          img{
            width: 45vw !important; 
            height: 21.2vw !important; 
          }
        }
      }
    }
  }
}
@media (max-width: 840px) {
  #landSale {
    #hero {
      height: 70vw;
      margin-top: 10vw !important;
      .container {
        .hero {
          height: 75vw;
          position: relative;
          justify-content: space-between;

          h1 {
            font-size: 215.374px !important;
            margin: 0 !important;
            position: absolute;
            top: 0;
          }
          h2 {
            font-size: 215px !important;
            margin-bottom: -30px;
            position: absolute;
            right: 0;
            top: 160px;
          }
          .ticket {
            position: absolute;
            top: 330px;
            font-size: 215px !important;
            left: 7%;
          }
          &--boxImg {
            width: 50vw !important;
            height: 15vw !important;
            margin: 0 !important;
            position: absolute;
            right: 0;
            top: -0.5vw;
          }
          &--linesImg {
            width: 38vw !important;
            margin: 0 !important;
            height: 15vw !important;
            position: absolute;
            left: 0;
            top: 19vw;
          }
          &--journey {
            position: absolute;
            bottom: 0;
            flex-direction: column;
            height: 18vw !important;
            justify-content: space-between;
            width: 100%;
            gap: 36px !important;
            h3 {
              font-size: 9vw !important;
            }
            &__array {
              display: none !important;
            }
            button {
              font-size: 4vw !important;
            }
          }
        }
      }
    }
    #section2 {
      .section2 {
        &--blocks {
          &__block {
            p{
              font-size: 2vw !important;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 820px) {
  #landSale {
    #hero {
      height: 70vw;
      .container {
        .hero {
          height: 75vw;
          position: relative;
          justify-content: space-between;

          h1 {
            margin: 0 !important;
            position: absolute;
            top: 0;
          }
          h2 {
            position: absolute;
            right: 0;
          }
          .ticket {
            position: absolute;
            font-size: 25vw !important;
          }
          &--boxImg {
            width: 50vw !important;
            height: 15vw !important;
            margin: 0 !important;
            position: absolute;
            right: 0;
            top: -0.5vw;
          }
          &--linesImg {
            width: 38vw !important;
            margin: 0 !important;
            height: 15vw !important;
            position: absolute;
            left: 0;
            top: 19vw;
          }
          &--journey {
            position: absolute;
            bottom: 0;
            flex-direction: column;
            height: 18vw !important;
            justify-content: space-between;
            width: 100%;
            h3 {
              font-size: 9vw !important;
            }
            &__array {
              display: none !important;
            }
            button {
              font-size: 4vw !important;
            }
          }
        }
      }
    }
    #section {
      margin-top: 27% !important;
    }
  }
}
@media (max-width: 768px) {
  #landSale {
    #hero {
      .container {
        .hero {
          h1 {
            font-size: 25vw !important;
          }
          h2 {
            font-size: 25vw !important;
            top: 18vw !important;
          }
          &--boxImg {
            width: 52vw !important;
          }
          &--linesImg {
            width: 43vw !important;
            margin: 0 !important;
            top: 18vw !important;
          }
          .ticket {
            top: 35vw !important;
            font-size: 23vw !important;
          }
          &--journey {
            top: 52vw !important;
            h3 {
              font-size: 8vw !important;
            }
          }
        }
      }
    }
    #section {
      height: 90vw !important;
    }
    #section2 {
      .section2 {
        &--blocks {
          &__block {
            max-width: 100%;
            p {
              font-size: 2vw !important;
            }
          }
          &__image {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  #landSale {
    #hero {
      .container {
        .hero {
          h2 {
            top: 18vw !important;
          }
          &--linesImg {
            top: 17.5vw !important;
          }
          .ticket {
            top: 36vw;
          }
          &--journey {
            h3 {
              font-size: 7vw !important;
            }
          }
        }
      }
    }
  }
}