@media (max-width: 769px) {
  #header {
    .header {
      align-items: start;
      &--left {
        display: none;
      }
      &--right {
        display: none;
      }
      &--center {
        margin-left: 0;
      }
      &--burger {
        right: 0;
        width: 300px;
        height: 110vh;
        margin-top: -28px;
        padding-left: 50px;
position: fixed;
        z-index: 5;
        &__menu {
          width: 73px;
          height: 73px;
          background: white;
          display: flex;
          flex-direction: column;
          position: relative;
          cursor: pointer;
          margin-right: 21px;
          margin-top: 28px;

          .icon {
            font-size: 75px;
            color: black;
            position: absolute;
          }

          .icon:nth-child(1) {
            top: -11px;
          }

          .icon:nth-child(2) {
            bottom: -13px;
          }
        }

        &__title {
          &--top {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 40px;

            .icon {
              font-size: 23px;
              color: black;
              margin: 0;
              cursor: pointer;

            }
          }

          &--bottom {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 40px;
a{
  margin-left: 0;
}
            h3 {
              margin-left: 0;
              color: black;
              font-family: Archivo;
              font-size: 20px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              text-transform: uppercase;
              cursor: pointer;
            }
          }
        }
      }


    }
  }
}

@media (max-width: 576px) {
  #header {
    .header {
      &--burger {
        width: 200px;
      }
    }
  }
}

@media (max-width: 400px) {
  #header {
    .header {
      &--center {
        img {
          width: 74px;
          height: 54.592px;
        }
      }

      &--burger {
        &__menu {
          width: 51px;
          height: 51px;
text-align: center;
          .icon{
            font-size: 53px;
            position: absolute;
          }
          .icon:nth-child(1){
            right: -1px;
          }
          .icon:nth-child(2){
          }

        }
      }
    }
  }
}
@media (max-width: 325px) {
  #header {
    .header {

      &--burger {
        width: 200px;
        padding-left: 22px;
        &__title{
          &--top{
            gap: 16px;
            .icon{
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}