#footer{
  padding-top: 7%;
  padding-bottom: 2%;
  .container{
    width: 95%;
    margin: 0 auto;
    .footer--line{
      width: 100%;
      height: 1px;
      background: gray;
      margin-bottom: 2%;
    }
  }
  .footer{
    .main-footLogo{
      width: 7vw;
    }
    .footer--info{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .footer--info__links{
      display: flex;
      width: 23%;
      justify-content: space-between;
      p{
        color: white;
        color: #FFF;
        font-family: Archivo, sans-serif;
        font-size: 1.3vw;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
    .footer--info__dop{
      p{
        color: white;
        color: #FFF;
        font-family: Archivo, sans-serif;
        font-size: 1.3vw;
        font-style: normal;
        font-weight: 500;
        line-height: 154.3%; /* 30.86px */
        text-transform: uppercase;
        text-align: end;
        margin: 4% 0 0 0;
      }

    }
    .footer--info__logos{
      display: flex;
      align-items: center;
      margin:  4% 0 ;

      img{
        cursor: pointer;
        margin: 0 1%;
      }

    }
    .footer-logo{
      color: white;
      font-size: 2.5vw; /* Используем относительный font-size */
      margin: 0 6%; 
      cursor: pointer;
      p{
        font-size: 2vw;
      }
    }


  }
}