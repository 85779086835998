@mixin h1 {
  color: #FFF;
  font-family: Alumni Sans;
  font-size: 15vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

#first {
  overflow: hidden;

  #hero {
    margin: 2% 0;

    .hero {
      &--top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        h1 {
          @include h1
        }

        &__line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0;
          gap: 3vw;

          img {
            width: 11vw;
          }

          h2 {
            @include h1;
            color: transparent;
            -webkit-text-stroke: 1px #FFFFFF;
          }
        }
      }

      &--circle {
        position: absolute;
        z-index: -1;
        right: 2%;
        top: -1%;
        width: 80%;
        height: 70%;
        flex-shrink: 0;
        border-radius: 50%;
        opacity: 0.5;
        background: radial-gradient(50% 50% at 50% 50%, #ec8425 0%, rgba(236, 132, 37, 0) 100%);
        filter: blur(175px);
      }

      &--center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: -7vw 0 -5vw 0;
        flex-wrap: wrap;

        h1 {
          @include h1;
          color: transparent;
          -webkit-text-stroke: 1px #FFFFFF;
        }

        img {
          width: 7vw;
        }

        &__line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0;

          h2 {
            @include h1;
          }
        }

      }

      h3 {
        color: #FFF;
        font-family: Alumni Sans;
        font-size: 5.8vw;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        text-transform: uppercase;
        flex-wrap: wrap;
      }
    }
  }

  #fronk {
    margin: 7% 0;
    position: relative;

    .circle {
      position: absolute;
      right: 6%;
      z-index: -1;
      top: 33%;
      width: 80%;
      height: 70%;
      flex-shrink: 0;
      border-radius: 50%;
      opacity: 0.5;
      background: radial-gradient(50% 50% at 50% 50%, #ec8425 0%, rgba(236, 132, 37, 0) 100%);
      filter: blur(175px);
    }

    .marquee {
      height: 10vw;
      width: 100%;
      overflow: hidden;
      position: relative;

      div {
        display: flex;
        width: 200%;
        height: 10vw;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        background: #e57208;
        animation: marquee 8s linear infinite reverse;

        h1 {
          color: #1b1a1a;
          float: left;
          width: 50%;
          font-family: Alumni Sans;
          font-size: 6.43vw;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
        }

        .transparent {
          -webkit-text-stroke: 1px #1b1a1a;
          color: transparent;
        }

        .icon {
          font-size: 10.8vw;
          margin: 0 2vw 0 0;
        }
      }
    }


    @keyframes marquee {
      0% {
        left: 0;
      }
      100% {
        left: 100%;
      }
    }

    .title {
      display: flex;
      align-items: start;
      justify-content: space-between;
      gap: 6%;
      margin-top: 9%;

      &--left {
        gap: 2vw;
        display: flex;
        flex-direction: column;

        width: 43%;
        text-align: start;

        h4 {
          color: #FFF;
          font-family: Archivo;
          font-size: 1.3vw;
          font-style: normal;
          font-weight: 500;
          line-height: 154.3%; /* 30.86px */
          text-transform: uppercase;
        }

        img {
          margin-left: 0;
          width: 27vw;
        }
      }

      &--video {
        width: 48vw;
        height: 26vw;
      }
    }
  }

  #avatar {
    margin: 13% 0;
    position: relative;

    .circle {
      position: absolute;
      left: 1%;
      bottom: -2%;
      z-index: -1;
      width: 80%;
      height: 70%;
      flex-shrink: 0;
      border-radius: 50%;
      opacity: 0.5;
      background: radial-gradient(50% 50% at 50% 50%, #ec8425 0%, rgba(236, 132, 37, 0) 100%);
      filter: blur(175px);
    }

    .marquee {
      height: 9vw;
      width: 105%;
      overflow: hidden;
      position: relative;
      margin-left: -2%;

      div {
        display: flex;
        width: 200%;
        height: 9vw;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        background: #e57208;
        animation: marquee 8s linear infinite;
       // animation: marquee 8s linear infinite reverse;
        h1 {
          color: #1b1a1a;
          float: left;
          width: 50%;
          font-family: Alumni Sans;
          font-size: 7.5vw;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
        }

        .transparent {
          -webkit-text-stroke: 1px #1b1a1a;
          color: transparent;
        }

        .icon {
          font-size: 10.8vw;
          margin: 0 2vw 0 0;
        }
      }
    }

    .marquee:nth-child(1) {
      height: 9vw;
      width: 105%;
      overflow: hidden;
      position: relative;
      transform: rotate(-4deg);
    }

    .marquee:nth-child(2) {
      height: 9vw;
      width: 105%;
      overflow: hidden;
      position: relative;
      transform: rotate(4deg);
      margin-top: -5%;
    }

    .avatar {
      display: flex;
      align-items: start;
      justify-content: space-between;
      margin-top: 10%;
      gap: 7%;

      .ava-img {
        padding: 1%;
        border: 2px solid #EC8425;
        width: 39vw;
        height: 38vw;
      }

      &--right {
        width: 48%;
        display: flex;
        flex-direction: column;
        gap: 8vw;

        h4 {
          color: #FFF;
          font-family: Archivo;
          font-size: 1.4vw;
          font-style: normal;
          font-weight: 500;
          line-height: 154.3%; /* 30.86px */
          text-transform: uppercase;
        }

        &__bottom {
          margin-left: 0;

          &--clothes {
            display: flex;
            justify-content: space-between;
            margin-bottom: 7%;

            img {
              margin: 0;
              width: 4vw;
              height: 5vw;
            }
          }
          .bottom-img{
            width: 41vw;
            height: 7vw;
          }
        }
      }
    }
  }

  #question {
    .marquee {
      height: 10vw;
      width: 100%;
      overflow: hidden;
      position: relative;

      div {
        display: flex;
        width: 200%;
        height: 10vw;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        background: #e57208;
        animation: marquee 8s linear infinite reverse;

        h1 {
          color: #1b1a1a;
          float: left;
          width: 50%;
          font-family: Alumni Sans;
          font-size: 6.25vw;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
        }

        .transparent {
          -webkit-text-stroke: 1px #1b1a1a;
          color: transparent;
        }

        .icon {
          font-size: 10.8vw;
          margin: 0 2vw 0 0;
        }
      }
    }

    @keyframes marquee {
      0% {
        left: 0;
      }
      100% {
        left: -100%;
      }
    }
    position: relative;

    .circle {
      position: absolute;
      right: 8%;
      top: 18%;
      width: 62%;
      height: 70%;
      flex-shrink: 0;
      border-radius: 50%;
      opacity: 0.5;
      background: radial-gradient(50% 50% at 50% 50%, #ec8425 0%, rgba(236, 132, 37, 0) 100%);
      filter: blur(175px);
    }

    .question {
      display: flex;
      flex-wrap: wrap;
      width: 85%;
      margin-top: 8%;

      &--quest {
        border: 1px solid white; /* Тонкая правая граница для разделения дивов */
        padding-bottom: 2%;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          h1 {
            font-family: Alumni Sans;
            font-size: 9vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            color: transparent;
            -webkit-text-stroke: 1px #ffffff;
            margin: 0;
            z-index: 1;
          }

          img {
            margin: 0 0 0 -5%;
          }
        }

        h3 {
          color: #FFF;
          font-family: Archivo;
          font-size: 1.4vw;
          font-style: normal;
          font-weight: 500;
          line-height: 154.3%; /* 30.86px */
          text-transform: uppercase;
          width: 83%;
        }
      }

      &--quest:nth-child(1) {
        width: 38.6vw;
        div{
          img{
            width: 5vw;
            height: 9vw;
          }
        }
      }

      &--quest:nth-child(2) {
        width:41.05vw;
        div{
          img{
            width: 7vw;
            height: 7vw;
          }
        }
      }

      &--quest:nth-child(3) {
        width: 31.4vw;
        div{
          img{
            width: 7vw;
            height: 7vw;
          }
        }
      }

      &--quest:nth-child(4) {
        width: 48.2vw;
        div{
          img{
            width: 7vw;
            height: 7vw;
          }
        }
      }
    }


  }

  #accordion {
    margin-top: 13%;
    position: relative;

    .circle {
      position: absolute;
      z-index: -1;
      left: 1%;
      top: 0;
      width: 80%;
      height: 70%;
      flex-shrink: 0;
      border-radius: 50%;
      opacity: 0.5;
      background: radial-gradient(50% 50% at 50% 50%, #ec8425 0%, rgba(236, 132, 37, 0) 100%);
      filter: blur(175px);
    }

    .container {
      .blocks {
        justify-content: space-around;
        display: flex;

        h1 {
          color: #fff;
          font-family: Alumni Sans, sans-serif;
          font-size: 16vw;
          letter-spacing: .5vw;
          font-weight: 700;
          margin: 0;
        }

        &--accordion {
          list-style-type: none;
          margin: 1%;
          padding: 1%;
          background: transparent;
          width: 60%;

          .item {
            margin-bottom: 1%;
            border-radius: 2%;
          }

          .ques {
            display: flex;
            justify-content: space-between;
            position: relative;
            padding: 3% 7% 2% .5%;
            border-top: 2px solid #ABABAB;
            text-align: left;
            cursor: pointer;
            color: #fff;
            font-family: Archivo;
            font-size: 2vw;
            font-weight: 700;
            line-height: 49.376px;
            text-transform: uppercase;

            .plus {
              margin: 0;
              font-size: 4vw;
            }
          }

          .answer {
            padding: 0 7% 4% .3%;
            background: transparent;
            text-align: left;
            font-size: 1.4vw;
            color: #ababab;
          }
        }
      }
    }
  }
}


