  #header{
    margin-top: 2.1%;
    .header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      &--left{
        display: flex;
        gap: 2vw;
        margin: 0;
        .icon{
          color: white;
          font-size: 1.8vw;
        }
      }
      img{
        width: 5.5vw ;
      }
      &--right{
        display: flex;
        align-items: center;
        gap: 2vw;
        margin: 0;
        h3{
          color: #FFF;
          font-family: Archivo;
          font-size: 1.1vw;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
    }
  }