@import "components/header/style.scss";
@import "components/header/media.scss";
@import "components/footer/style.scss";
@import "components/footer/media.scss";
@import "components/firstPage/style.scss";
@import "components/firstPage/media.scss";
@import "components/secondPage/media.scss";
@import "components/secondPage/style";
@import "components/mintPage/media.scss";
@import "components/mintPage/style";


@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alumni+Sans:wght@100;200;300;400;500;600;700;800;900&family=Archivo:wght@100;200;300;400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alumni+Sans:wght@100;200;300;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

* {
  margin: 0 auto;
  padding: 0;
}
body{
  background: url("img/Rectangle 194.png");
}

.container {
  width: 95%;
  a {
    text-decoration: none;
  }
}
