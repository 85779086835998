#landSale {
  #hero {
    margin-top: 5%;
    .container {
      .hero {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 2%;
        // height: 50%;
        position: relative;
        

        &--circle {
          position: absolute;
          right: 2%;
          top: -1%;
          width: 40%;
          height: 30%;
          flex-shrink: 0;
          border-radius: 60%;
          opacity: 0.5;
          background: radial-gradient(
                          50% 50% at 50% 50%,
                          #ec8425 0%,
                          rgba(236, 132, 37, 0) 100%
          );
          filter: blur(175px);
        }

        h1 {
          color: #fff;
          font-family: Alumni Sans;
          font-size: 15vw;
          font-style: normal;
          font-weight: 700;
          line-height: 14vw;
          text-transform: uppercase;
          margin: -5px 0;
        }

        h2 {
          font-family: Alumni Sans;
          font-size: 15vw;
          font-style: normal;
          font-weight: 700;
          line-height: 14vw;
          text-transform: uppercase;
          margin: 0;
          color: transparent;
          -webkit-text-stroke: 1px #ffffff;
        }

        &--boxImg {
          width: 35vw;
          height: 9vw;
          // object-fit: cover;
        }
        &--linesImg{
          margin: 0;
          // object-fit: cover;
          width: 35vw;
          height: 9vw;
        }

        &--journey {
          display: flex;
          align-items: center;
          gap: 2%;
          justify-content: space-between;
          width: 100%;
          margin: 0;

          h3 {
            color: #fff;
            font-family: Alumni Sans;
            font-size: 5vw !important;
            // font-style: normal;
            font-weight: 200;
            line-height: 7vw;
            text-transform: uppercase;
            // flex-wrap: wrap;
            margin: 0;
          }
          &__array {
            display: flex;
            align-items: center;
            width: 23vw!important;
            margin: 0;
          }
          button {
            color: #fff;
            font-family: Archivo;
            font-size: 2.5vw;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: uppercase;
            border: 1px solid #d9d9d9;
            background: transparent;
            padding: 0.5%;
            margin: 0;
          }
        }
      }
    }
  }
  #section {
    position: relative;
    .circle {
      position: absolute;
      z-index: -1;
      left: 0;
      top: 4%; 
    width: 35%; 
    height: 26%; 
    flex-shrink: 0;
    border-radius: 50%;
      opacity: 0.5;
      background: radial-gradient(50% 50% at 50% 50%, #ec8425 0%, rgba(236, 132, 37, 0) 100%);
      filter: blur(20vw);
    }
    .second {
      position: relative;
      margin-top: 10vw;
      display: flex;
      justify-content: space-between  ;
      &--line {
        position: absolute;
        background: linear-gradient(90deg, #e57208 0%, rgba(229, 114, 8, 0) 100%);
        width: 55%;
        top: 0vw;
        height: 25vw;
      }
      &--page {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__left {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h1 {
            color: #fff;
            font-family: Alumni Sans;
            font-size: 14vw; /* Заменил абсолютное значение на относительное */
          font-style: normal;
          font-weight: 700;
          line-height: 68%; /* 131.711px */
            text-transform: uppercase;
            margin: 0;
            z-index: 2;
            left: 2vw;
            position: absolute;
            top: -1.7vw;
          }
          img {
            position: absolute;
            left: 45vw;
            width: 37vw; 
          height: auto; 
          top: -8vw;
          z-index: 2;
          margin: 1% 0 0 -25%;
          }
        }
        &__title {
          z-index: 2;
          display: flex;
          flex-direction: column;
          margin: 0;
          gap: 2vw;
          p {
            color: #fff;
            font-family: Archivo;
            font-size: 1.5vw; 
            width: 42vw;
            font-style: normal;
            font-weight: 500;
            line-height: 154.3%;
            text-transform: uppercase;
          }
          &--morthe {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1vw; 
            margin: 0;
            &__first {
              width: 86%;
              height: 1.43vw; 
              background: #e57208;
            }
            &__second {
              width: 15%;
              height: 1.43vw;
              background: #e57208;
            }
            &__third {
              width: 9%;
              height: 1.43vw;
              background: #e57208;
            }
            &__fourth {
              width: 25%;
              height: 1.43vw;
              background: #e57208;
            }
          }
          &--text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            h3 {
              font-family: Alumni Sans;
              font-size: 2vw;
              font-style: normal;
              font-weight: 700;
              margin: 0;
              line-height: 67%;
              text-transform: uppercase;
              color: #000000;
              -webkit-text-stroke: 0.03vw #ffffff; 
            }
          }
        }
      }
    }
  }
  #section2 {
    margin-top: 13vw  ;
    .marquee {
      height: 10vw;
      width: 100%;
      overflow: hidden;
      position: relative;
      div {
        display: flex;
        width: 200%;
        height: 10vw;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        background: #e57208;
        animation: marquee 8s linear infinite reverse;
        h1 {
          color: #1b1a1a;
          float: left;
          width: 50%;
          font-family: Alumni Sans;
          font-size: 8vw;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
        }
        .transparent {
          -webkit-text-stroke: 1px #1b1a1a;
          color: transparent;
        }
        .icon {
          font-size: 10.8vw; 
        margin: 0 2vw 0 0;
        }
      }
    }
    @keyframes marquee {
      0% {
        left: -100%;
      }
      100% {
        left: 0;
      }
    }
  }
  #section2 {
    .container {
      .section2 {
        margin-top: 5vw;
        &--blocks {
          display: flex;
          flex-wrap: wrap;         
          justify-content: space-around;
          &__block {
            padding: 2.5vw;
          width: 40vw; 
          height: 9vw; 
            flex-shrink: 0;
            border: 1px solid #d9d9d9;
            margin: 2vw 0;
            h1 {
              color: #eee;
              font-family: Archivo;
              font-size: 3vw;
              font-style: normal;
              font-weight: 500;
              line-height: 154.3%;
              text-transform: uppercase;
            }
            p {
              color: #636363;
              font-family: Archivo;
              font-size: 1.2vw;
              font-style: normal;
              font-weight: 300;
              line-height: 154.3%; 
              text-transform: uppercase;
            }
          }
          &__image {
            width: 45vw; 
          height: 14.2vw;
            margin: 2vw 0;
          }
        }
      }
    }
  }
}
